import { get } from 'lodash';
import { Duration } from 'luxon';

import { components } from '@@types/CatalogueApi';
import OnDemand from '@@types/OnDemand';

import {
  getImageIdByProperties,
  transformAvailability, transformCasts, transformClassification, transformConsumerAdvices,
  transformCrewToDirectors, transformEntityTypeToSeriesType, transformLanguages, transformTextTracksToSubtitles,
} from './CatalogApiCommonTransformer';

type EpisodeData = components['schemas']['tvEpisode'] | components['schemas']['newsEpisode'] | components['schemas']['sportsEpisode'];
const transform = (data: EpisodeData): OnDemand.Episode => {
  const availability = transformAvailability(data.availability);
  const seriesType = transformEntityTypeToSeriesType(data.entityType);
  const distributors = get(data, 'distributors', []) as OnDemand.Distributor[];

  const playerTitle = data.seriesTitle;
  let playerSubtitle = data.title;

  if (data.entityType === 'TV_EPISODE' && data.seasonNumber > 0) {
    playerSubtitle = `S${data.seasonNumber} E${data.episodeNumber}: ${data.title}`;
  }

  return {
    availableDate: availability.availableDate,
    available: availability.available,
    cast: transformCasts(data.casts),
    channels: data.channels,
    classification: 'classificationID' in data ? transformClassification(data.classificationID) : null,
    consumerAdvices: transformConsumerAdvices(data.consumerAdvices),
    consumerAdviceTexts: data.consumerAdviceTexts,
    countries: data.countries,
    description: data.description,
    duration: Duration.fromISO(data.duration).as('seconds'),
    directors: transformCrewToDirectors(data.crews),
    distributors,
    expired: availability.expired,
    expiredDate: availability.expiredDate,
    genres: data.genres,
    hasClosedCaption: get(data, 'textTracks').length > 0,
    hasAudioDescription: get(data, 'hasAudioDescription', false),
    id: data.mpxMediaID.toString(),
    entityType: data.entityType,
    catalogueId: data.id,
    odImageId: getImageIdByProperties(data.images, { ratio: '16:9', type: 'KEY_ART' }),
    ogImageId: getImageIdByProperties(data.images, { ratio: '16:9', type: 'BANNER' }),
    isLiveStream: false,
    isHighDefinition: get(data, 'qualities').includes('HD'),
    languages: transformLanguages(get(data, 'languages')),
    mediaTitle: data.title,
    metaDescription: data.description.replace(/[\r\n]+/g, ' '),
    pageTitle: data.title,
    pilatId: data.trackingID,
    playerTitles: {
      title: playerTitle,
      subtitle: playerSubtitle,
    },
    publicationYear: 'releaseYear' in data ? data.releaseYear : null,
    route: {
      name: 'seriesEpisode',
      params: {
        id: data.mpxMediaID.toString(),
        seriesSlug: data.seriesSlug,
        ...(data.seasonSlug && { seasonSlug: data.seasonSlug }),
        episodeSlug: data.slug,
        seriesType,
      },
    },
    subtitles: transformTextTracksToSubtitles(data.textTracks),
    type: 'Episode',
    title: data.title,
    trailerId: get(data, 'trailers.0.mpxMediaID'),
    episodeData: {
      seriesId: data.seriesID,
      seriesSlug: data.seriesSlug,
      programName: data.seriesTitle,
      seasonNumber: data.seasonNumber,
      episodeNumber: data.episodeNumber,
      seasonSlug: data.seasonSlug,
      seriesType,
      seriesEntityType: data.entityType.replace('_EPISODE', '_SERIES'),
      seriesRoute: {
        name: 'series',
        params: {
          seriesType,
          slug: data.seriesSlug,
        },
      },
    },
    // episode doesn't have announcement
    announcement: null,
    cdpTitle: 'cdpTitle' in data ? data.cdpTitle : null,

    // these attributes not transformed because they are not available, left here because they are still expected in data layer
    airDate: '',
    award: '',
    collections: [],
    eras: [],
    groupings: [],
    keywords: [],
    locations: [],
    organisations: [],
    places: [],
    seriesCrid: '',
    subgenres: [],
    subjects: [],
    themes: [],
    tones: [],
    useType: '',
  };
};

export default { transform };
