import * as ls from 'local-storage';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import useLocalTimeZone from '@@src/hooks/useLocalTimeZone';
import { REWIND_PROMO } from '@@utils/constants';

const useRewindBannerEnabled = () => {
  const [enabled, setEnabled] = useState(false);
  const zone = useLocalTimeZone();
  const localStorageKey = 'od.rewindPromo2024.hide';

  useEffect(() => {
    ls.backend(localStorage);

    const signOutHandler = () => {
      ls.remove(localStorageKey);
    };

    document.addEventListener('OdWebsite_SignOutSuccess', signOutHandler);
    return () => {
      document.removeEventListener('OdWebsite_SignOutSuccess', signOutHandler);
    };
  }, []);

  /**
   * calculation should be done client side only
   */
  useEffect(() => {
    if (REWIND_PROMO?.url !== '' && typeof window !== 'undefined') {
      const startDateTime = DateTime.fromISO(`${REWIND_PROMO.startDate}`).setZone(zone);
      const endDateTime = DateTime.fromISO(REWIND_PROMO.endDate).setZone(zone);
      const nowTime = DateTime.local();

      if (startDateTime > nowTime || nowTime > endDateTime) {
        setEnabled(false);
      } else {
        setEnabled(true);
      }
    }
  }, [zone]);

  return enabled;
};

export default useRewindBannerEnabled;
