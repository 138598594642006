// Based on https://github.com/Horat1us/detect-ad-block
import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import { getSetting, switchOff, switchOn, setSetting } from '@@stores/SettingsStore';
import Logger from '@@utils/logger/Logger';

declare global {
  interface Window {
    google_srt: number;
  }
}

let abDetected = false;
let running = false;
let runTimeoutHandler;

/* istanbul ignore next */
export default function useDetectAdBlocker(): boolean {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const adBlockDetected = useAppSelector((state) => {
    return getSetting(state, 'adBlockDetected');
  });

  const handleDetectionResponse = useCallback((detected: boolean) => {
    if (detected) {
      abDetected = true;
      dispatch(switchOn('adBlockDetected'));
      Logger.info('Ad blocker detected');
    }
  }, [dispatch]);

  const detectByHttp = useCallback((url: string, onLoadChecks: (body: string) => boolean): Promise<boolean> => {
    return new Promise<boolean>((end) => {
      const readyStates = [false, false, false, false];

      const respond = (responseForce?: boolean) => {
        if (responseForce !== undefined) {
          end(responseForce);
        } else {
          for (let i = 0; i < 4; i += 1) {
            if (readyStates[i] === false) {
              end(true);
              return;
            }
          }

          end(false);
        }
      };

      const xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = () => {
        readyStates[xmlHttp.readyState - 1] = true;
        if (xmlHttp.readyState === 4) {
          respond();
        }
      };

      if (onLoadChecks) {
        xmlHttp.onload = () => {
          handleDetectionResponse(onLoadChecks(xmlHttp.responseText));
        };
      }

      try {
        xmlHttp.open('GET', url, true);
        xmlHttp.send();
      } catch (e) {
        // Detect script blocked by Content Security Policy (CSP)
        if (e.result === '2153644038') {
          respond(true);
        }
      }
    });
  }, [handleDetectionResponse]);

  const startDetection = useCallback(async () => {
    running = true;
    const cacheBuster = new Date().getTime();

    if (abDetected === false) {
      handleDetectionResponse(await detectByHttp(
        `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?ord=${cacheBuster}`,
        (body: string) => {
          // uBlock Origin does an internal redirects and spoofs the content
          return body.indexOf('b.google_reactive_tag_first') === -1 || typeof (window.google_srt) === 'undefined';
        },
      ));

      handleDetectionResponse(await detectByHttp(
        `https://sbs.demdex.net/dest5.html?d_nsid=0&ord=${cacheBuster}`,
        (body: string) => {
          // uBlock Origin does an internal redirects and spoofs the content
          return body.indexOf('Demdex.canSetThirdPartyCookies') === -1;
        },
      ));

      if (abDetected === false) {
        dispatch(switchOff('adBlockDetected'));
      }
    } else {
      dispatch(switchOn('adBlockDetected'));
    }

    running = false;
    runTimeoutHandler = undefined;
  }, [detectByHttp, dispatch, handleDetectionResponse]);

  useEffectOnce(() => {
    runTimeoutHandler = setTimeout(startDetection, 250);
  });

  useEffect(() => {
    // If watch page then restart detection in case the user has re-enabled the blocker before clicking play
    if (history.location.pathname.indexOf('/watch') !== -1) {
      if (!running && !runTimeoutHandler) {
        abDetected = false;
        dispatch(setSetting({ key: 'adBlockDetected', value: undefined }));
        runTimeoutHandler = setTimeout(startDetection, 250);
      }
    }
  }, [dispatch, history.location.pathname, startDetection]);

  return adBlockDetected;
}
