import * as ls from 'local-storage';
import { FocusEventHandler, FunctionComponent, KeyboardEventHandler, MouseEventHandler } from 'react';
import { generatePath, matchPath, Link } from 'react-router-dom';

import { SSRFunctionComponent } from '@@types/ssr';
import { hasFeature } from '@@utils/config';

import {
  EpisodePage,
  FavouritesPage,
  LiveTvPage,
  MoviePage,
  Page,
  ProgramPage,
  ProgramPageById,
  SearchPage,
  SeriesPage,
  VideoPage,
  CollectionPage,
  WatchPageV2,
  WatchPage,
  ErrorPage,
  NotFoundPage,
  PersonPage,
} from './pages/AsyncPages';
import { BASENAME, WEB_HOST } from './utils/constants';
import Logger from './utils/logger/Logger';

export interface OdRoute {
  path: string;
  component: SSRFunctionComponent;
  exact: boolean;
  disableSSR?: boolean;
  /** feature flag routes */
  disabled?: boolean;
}
export const routes = {
  home: {
    path: '/',
    component: Page,
    exact: true,
  },
  tvShows: {
    path: '/tv-shows',
    component: Page,
    exact: true,
  },
  movies: {
    path: '/movies',
    component: Page,
    exact: true,
  },
  liveTv: {
    path: '/live',
    component: LiveTvPage,
    exact: true,
  },
  news: {
    path: '/news',
    component: Page,
    exact: true,
  },
  sport: {
    path: '/sport',
    component: Page,
    exact: true,
  },
  favourites: {
    path: '/favourites',
    component: FavouritesPage,
    exact: true,
  },
  seriesEpisode: {
    path: '/:seriesType(tv|news|sports)-series/:seriesSlug/:seasonSlug?/:episodeSlug/:id([0-9]+)',
    component: EpisodePage,
    exact: true,
  },
  series: {
    path: '/:seriesType(tv|news|sports)-series/:slug/:seasonSlug?',
    component: SeriesPage,
    exact: true,
  },
  // catalogue api movie
  movie: {
    path: '/movie/:slug/:id([0-9]+)',
    component: MoviePage,
    exact: true,
  },
  program: {
    path: '/:programType(tv|news|sports)-program/:slug/:id([0-9]+)',
    component: MoviePage,
    exact: true,
  },

  // we need to keep the old urls for redirections
  // and also in some cases for marketing LOTE content until all apps are migrated to the Catalogue API
  v3SeriesById: {
    path: '/program/:id([0-9]+)',
    component: ProgramPageById,
    exact: true,
  },
  v3Series: {
    path: '/program/:slug',
    component: ProgramPage,
    exact: true,
  },
  video: {
    path: '/video/:id([0-9]+)/:slug(.*)?',
    component: VideoPage,
    exact: true,
  },
  collection: {
    path: '/collection/:slug(.*)',
    component: CollectionPage,
    exact: true,
  },
  watch: {
    path: '/watch/:id',
    component: (ls.get('player-version') === 1 || !hasFeature('watchPageV2')) ? WatchPage : WatchPageV2,
    exact: true,
    disableSSR: true,
  },
  watchNotFound: {
    path: '/watch/:id/*',
    component: NotFoundPage,
    exact: true,
    disableSSR: true,
  },
  person: {
    path: '/person/:slug(.*)',
    component: PersonPage,
    exact: true,
  },
  search: {
    path: '/search/:query',
    component: SearchPage,
    exact: true,
  },
  error: {
    path: '/error',
    component: ErrorPage,
    exact: true,
  },
  // If pathname is a URL to a file (has an extension) then don't query the page API
  pathIsFile: {
    path: '/*.(js|css|jpg|gif|png|json|xml)',
    component: NotFoundPage,
    exact: true,
  },
  page: {
    path: '/:slug(.*)',
    component: Page,
    exact: true,
  },
};

export type RouteName = keyof typeof routes;

/**
 * Get route from name
 * @param name
 */
export const getRouteFromName = (name: RouteName): OdRoute => {
  return routes[name];
};

/**
* Get path from name
* @param name
*/
export const getPathFromName = (name: RouteName): string => {
  return getRouteFromName(name).path;
};

/**
 * Get route from path
 * @param path
 */
export const getRouteFromPath = (path: string) => {
  let route = null;
  Object.keys(routes).forEach((key) => {
    if (matchPath(path, routes[key])) {
      route = routes[key];
    }
  });

  return route;
};

/**
 * Get component from url
 * @param url
 */
export const getComponentFromUrl = (url: string) => {
  const route = getRouteFromPath(url);
  return route ? route.component : null;
};

export interface OdLinkProps {
  name?: RouteName;
  params?: { [paramName: string]: string | boolean | number };
  query?: { [queryName: string]: string | boolean | number };
  children?: any;
  to?: string;
  className?: string;
  tabIndex?: number;
  onClick?: any;
  onMouseOver?: MouseEventHandler<HTMLAnchorElement>;
  onMouseLeave?: MouseEventHandler<HTMLAnchorElement>;
  onBlur?: FocusEventHandler<HTMLAnchorElement>;
  onKeyPress?: KeyboardEventHandler<HTMLAnchorElement>;
  onKeyDown?: KeyboardEventHandler<HTMLAnchorElement>;
  onContextMenu?: any;
  target?: '_blank';
  rel?: string;
  title?: string;
  role?: 'link' | 'button' | 'application';
}

export function generatePathFromLinkProps({
  name, params, query, to,
}: OdLinkProps): string {
  if (to) {
    return to;
  }

  let route;

  if (name) {
    route = getRouteFromName(name);
  }

  if (!route) {
    throw new Error(`Unable to find route '${name}'`);
  }

  let path;

  try {
    // generate path and trim the trailing slash except for '/'
    path = generatePath(route.path, params).replace(/^(.+)\/$/, '$1');

    if (query) {
      const queryString = Object.keys(query).map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`;
      }).join('&');

      if (queryString) {
        path += `?${queryString}`;
      }
    }
  } catch (e) {
    Logger.error(new Error('Unable to generate path'), {
      linkProps: {
        name,
        params,
        query,
      },
      error: {
        message: e.message,
      },
    });
    throw (e);
  }

  return path;
}

export function generateFullPathFromLinkProps(props: OdLinkProps, language: string) {
  let basename = BASENAME;
  if (language && language !== 'en') {
    basename += `/${language}`;
  }

  return `${basename}${generatePathFromLinkProps(props)}`;
}

export function generateFullUrlFromLinkProps(props: OdLinkProps, language: string) {
  return `${WEB_HOST}${generateFullPathFromLinkProps(props, language)}`;
}

export function generateFullUrlFromPath(path: string, language: string = undefined) {
  let basename = BASENAME;
  if (language && language !== 'en') {
    basename += `/${language}`;
  }

  return `${WEB_HOST}${basename}${path}`;
}

export function ensureFullUrl(url: string): string {
  if (!url.match(/^https?:\/\//)) {
    return new URL(url, WEB_HOST).toString();
  }

  return url;
}

export const OdLink: FunctionComponent<OdLinkProps> = ({
  name,
  params = {},
  query = {},
  children,
  to,
  ...rest
}) => {
  if (name) {
    const path = generatePathFromLinkProps({ name, params, query });
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Link to={path} {...rest}>{children}</Link>;
  }

  if (!to) {
    throw new Error('`name` or `to` is required');
  }

  // Render a tag if `to` is a full url
  if (/^https?:\/\//.test(to)) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <a href={to} rel="noopener noreferrer" {...rest}>{children}</a>;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Link to={to} {...rest}>{children}</Link>;
};

export default {
  routes,
  getRouteFromName,
  getRouteFromPath,
  getComponentFromUrl,
  generatePathFromLinkProps,
  OdLink,
};
