import { Duration } from 'luxon';

import { StreamHandlerConfig } from '@@src/lib/VideoPlayerV2/StreamHandlers/StreamHandler';
import { CatalogApiImage, getImageIdByProperties } from '@@src/transformers/CatalogApiCommonTransformer';
import { components } from '@@types/PlaybackApi';
import {
  PlaybackStreamData,
  PlaybackEvents,
  PlaybackEventTypes,
  SupportedClassifications, SupportedClassification,
} from '@@types/PlaybackStreamData';
import { BUILD_HASH } from '@@utils/constants';

const transform = (data: components['schemas']['models.StreamOutput']): PlaybackStreamData => {
  const playbackEvents: PlaybackEvents = {};

  if (data.playbackEvents) {
    data.playbackEvents.forEach((playbackEvent) => {
      if (PlaybackEventTypes.includes(playbackEvent.type)) {
        playbackEvents[playbackEvent.type] = Duration.fromISO(playbackEvent.time).as('seconds');
      }
    });
  }

  const streamHandlerConfigs: StreamHandlerConfig[] = [];

  data.streamProviders.forEach((streamProvider) => {
    if (streamProvider.type === 'GoogleDAI') {
      streamHandlerConfigs.push({
        type: 'GoogleDAI',
        videoId: streamProvider.videoID,
        assetKey: streamProvider.assetKey,
        adTagParameters: streamProvider.adTagParameters,
        contentSourceId: streamProvider.contentSourceID,
      });
    }

    if (streamProvider.type === 'HLS') {
      streamHandlerConfigs.push({
        type: 'HLS',
        url: streamProvider.url,
        textTracks: streamProvider?.textTracks?.map((textTrack) => {
          return {
            language: textTrack.lang,
            label: textTrack.name,
            url: textTrack.url,
          };
        }),
      });
    }
  });

  const sanitiseClassification = (classificationId): SupportedClassification | null => {
    const supportedClassification = SupportedClassifications.filter((_supportedClassification) => {
      return _supportedClassification === classificationId;
    });

    if (supportedClassification.length === 1) {
      return supportedClassification[0];
    }

    return null;
  };

  let playerTitle = data.title;
  let playerSubtitle = '';

  if (data.entityType.includes('_EPISODE')) {
    playerTitle = data.seriesTitle;
    playerSubtitle = data.title;

    if (data.entityType === 'TV_EPISODE' && data.seasonNumber > 0) {
      playerSubtitle = `S${data.seasonNumber} E${data.episodeNumber}: ${data.title}`;
    }
  }

  return {
    duration: Duration.fromISO(data.duration).as('seconds') || 0,
    cdpTitle: data.cdpTitle,
    entityType: data.entityType,
    streamType: data.streamType,
    title: playerTitle,
    subtitle: playerSubtitle,
    reportingTitle: data.reportingTitle,
    mpxId: data.externalIDs.mpxMediaID,
    streamHandlerConfigs,
    playbackEvents,
    classification: sanitiseClassification(data.classificationID),
    consumerAdviceTexts: data.consumerAdviceTexts || [],
    ogImageId: getImageIdByProperties((data.images as CatalogApiImage[]), { ratio: '16:9', type: 'BANNER' }),
    ...(data?.externalIDs?.ozTamPublisherID && {
      oztam: {
        publisherId: data.externalIDs.ozTamPublisherID,
        vendorVersion: `SBSOnDemand_Web_2.0-${BUILD_HASH}`,
        env: process.env.BVAR_OZTAM_ENV || 'test',
        videoId: data.externalIDs.trackingID || data.externalIDs.mpxMediaID,
        duration: Duration.fromISO(data.duration).as('seconds') || 0,
        isLiveStream: data.streamType === 'live',
      },
    }),
  };
};

export default { transform };
