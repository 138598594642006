import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles';
import clsx from 'clsx';
import * as ls from 'local-storage';
import { pickBy } from 'lodash';
import { DateTime } from 'luxon';
import { FunctionComponent, ReactNode } from 'react';

import { messageBannerStyles } from '@@src/components/MessageBanner/MessageBanner';
import { OdLink } from '@@src/routes';

import orange from '../../styles/colors/orange';

const useMessageBannerStyles = makeStyles(messageBannerStyles);

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
      bottom: 'initial',
      margin: 0,
      width: '100%',
      fontSize: '0.875rem',
      borderRadius: 0,
      backgroundColor: orange.orange,
      '&.show': {
        bottom: 'initial',
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'normal',
      },
    },
    link: {
      '&:hover, .Mui-focusVisible &': {
        background: orange.grandis,
      },
    },
    notificationTexts: {
      flexDirection: 'column',
      alignItems: 'start',
    },
    notificationTitleContainer: {
      [theme.breakpoints.down('md')]: {
        backgroundColor: 'unset',
        padding: '13px 16px 8px 16px',
        borderRadius: '4px 4px 0 0',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '13px 16px 8px 16px',
      },
    },
    notificationDescription: {
      [theme.breakpoints.down('md')]: {
        padding: '0 24px 16px 24px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0 20px 16px 20px',
      },
    },
  });
});

export interface MessageBannerProps {
  title: string;
  description: string;
  onClick?: () => void;
  link?: string;
  icon?: ReactNode;
  classes?: Partial<ClassNameMap<ClassKeyOfStyles<typeof useStyles>>>
}

const localStorageKey = 'od.messageBannerExp';

export function clearMessageBannerExpiredValues() {
  const values = ls.get<Record<string, number>>(localStorageKey) || {};

  ls.set(localStorageKey, pickBy(values, (expiry) => {
    const expiryDate = DateTime.fromMillis(expiry);
    return expiryDate.diffNow().as('seconds') > 0;
  }));
}

const MenuMessageBanner: FunctionComponent<MessageBannerProps> = (props) => {
  const {
    title, description, icon = null, link = null,
    onClick,
  } = props;
  const classes = useStyles(props);
  const messageBannerClasses = useMessageBannerStyles(props);

  const bannerElement = (
    <div className={clsx(messageBannerClasses.notificationTexts, classes.notificationTexts)}>
      <div className={clsx(messageBannerClasses.notificationTitleContainer, classes.notificationTitleContainer)}>
        <div className={messageBannerClasses.bannerTitle}>
          {icon && <span className={messageBannerClasses.bannerIcon}>{icon}</span>}
          {title}
        </div>
      </div>
      <div className={clsx(messageBannerClasses.notificationDescription, classes.notificationDescription)}>{description}</div>
    </div>
  );

  if (link) {
    return (
      <OdLink
        className={clsx(messageBannerClasses.root, classes.root, classes.link)}
        data-testid="notification-banner"
        tabIndex={0}
        to={link}
        onClick={onClick}
      >
        {bannerElement}
      </OdLink>
    );
  }

  return (
    <div
      className={clsx(messageBannerClasses.root, classes.root)}
      data-testid="notification-banner"
    >
      {bannerElement}
    </div>
  );
};

export default MenuMessageBanner;
