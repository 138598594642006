import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles';
import clsx from 'clsx';
import { FunctionComponent, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ChevronRight from '@@icons/ChevronRight';

const useStyles = makeStyles(() => {
  return createStyles({
    viewMoreButton: {
      display: 'flex',
      flexDirection: 'row',
      gap: 8,
      backgroundColor: 'transparent',
      border: 'none',
      color: 'inherit',
      fontSize: 'inherit',
      fontFamily: 'inherit',
      '&:hover': {
        cursor: 'pointer',
        fontWeight: 'bold',
      },
      padding: 0,
    },
    chevron: {
      position: 'relative',
      display: 'inline',
      fontSize: 12,
      alignSelf: 'center',
      margin: '0',

      '& path': {
        fill: 'rgba(255, 255, 255, 0.6)',
      },

      '&.expanded': {
        transform: 'rotate(180deg)',
      },

      '&.vertical': {
        transform: 'rotate(90deg)',
        '&.expanded': {
          transform: 'rotate(270deg)',
        },
      },
    },
  });
});

export interface ViewMoreButtonProps {
  onExpand: () => void;
  onCollapse?: () => void;
  chevronPosition?: 'before' | 'after';
  chevronDirection?: 'horizontal' | 'vertical',
  id?: string;
  expandLabel?: string;
  collaspeLabel?: string;
  classes?: Partial<ClassNameMap<ClassKeyOfStyles<typeof useStyles>>>;
}

const ViewMoreButton: FunctionComponent<ViewMoreButtonProps> = ({
  onExpand, onCollapse, expandLabel = undefined, collaspeLabel = undefined, id = undefined,
  chevronPosition = 'before', chevronDirection = 'horizontal', classes: overrideClasses, ...props
}) => {
  const classes = useStyles({ classes: overrideClasses });
  const { t } = useTranslation('common');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    const willExpand = !isExpanded;
    setIsExpanded(willExpand);
    if (willExpand) {
      onExpand();
    } else if (onCollapse) {
      onCollapse();
    }
  }, [isExpanded, onCollapse, onExpand]);

  let label = expandLabel || t('common:navigation.viewMore');
  if (isExpanded) {
    label = collaspeLabel || t('common:navigation.viewLess');
  }

  return (
    <button
      id={id}
      type="button"
      onClick={handleClick}
      className={classes.viewMoreButton}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      {chevronPosition === 'before' && <ChevronRight className={clsx(classes.chevron, { expanded: isExpanded, vertical: chevronDirection === 'vertical' })}/>}
      <span>{label}</span>
      {chevronPosition === 'after' && <ChevronRight className={clsx(classes.chevron, { expanded: isExpanded, vertical: chevronDirection === 'vertical' })}/>}
    </button>
  );
};

export default ViewMoreButton;
