import { ClassNameMap } from '@material-ui/styles';
import { t } from 'i18next';
import { Duration } from 'luxon';
import { forwardRef, RefObject, PropsWithChildren } from 'react';

import OnDemand2 from '@@src/@types/OnDemand2';
import OdFavouriteButtonV2 from '@@src/components/Buttons/OdFavouriteButtonV2';
import Metadata from '@@src/components/Tiles/TilesV2/Metadata';
import AvailableDateTimeLabel from '@@src/components/Utils/AvailableDateTimeLabel';
import ClassificationBadge from '@@src/components/Utils/ClassificationBadge';
import { durationShortFormatParams, durationShortFormatParamsWithSeconds } from '@@src/i18n';
import OnDemand from '@@types/OnDemand';

import BaseTile from './BaseTile';

// ignoreFunctionalComponents does not work for forwardRef https://github.com/yannickcr/eslint-plugin-react/issues/2856
/* eslint-disable react/require-default-props */
export interface VideoTileProps {
  collectionTileItem: OnDemand2.CollectionItemMovie | OnDemand2.CollectionItemEpisode | OnDemand2.CollectionItemProgram | OnDemand2.CollectionItemClip
  displayType: '2:3' | '16:9';
  className?: string;
  onFavourited?: (item: Partial<OnDemand2.CollectionItemVideo | OnDemand2.CollectionItemSeries>) => void;
  onUnfavourited?: (item: Partial<OnDemand2.CollectionItemVideo | OnDemand2.CollectionItemSeries>) => void;
  onActivate?: () => void;
  onDeactivate?: () => void;
  canBeFocused?: boolean;
  onClick?: () => void;
  classes?: ClassNameMap;
}
/* eslint-enable react/require-default-props */

const VideoTile = forwardRef<HTMLDivElement, PropsWithChildren<VideoTileProps>>((props, ref: RefObject<HTMLDivElement>) => {
  const {
    collectionTileItem,
    displayType,
    onFavourited,
    onUnfavourited,
    onActivate,
    onDeactivate,
    canBeFocused,
    onClick, classes,
    ...other
  } = props;

  let playIcon = false;

  let metadata = [];
  let classificationBadge;
  let videoIdForProgress;

  let ctaButton = (
    <OdFavouriteButtonV2
      item={collectionTileItem}
      onClickAdd={() => {
        onFavourited(collectionTileItem);
      }}
      onClickRemove={() => {
        onUnfavourited(collectionTileItem);
      }}
    />
  );

  let duration;
  if (collectionTileItem.duration) {
    const durationSeconds = Duration.fromISO(collectionTileItem.duration).as('seconds');
    duration = t('video.duration', {
      duration: Duration.fromISO(collectionTileItem.duration).as('seconds'),
      formatParams: {
        duration: durationSeconds >= 60 ? durationShortFormatParams : durationShortFormatParamsWithSeconds,
      },
    });
  }

  let subtitle = collectionTileItem.genre;

  switch (collectionTileItem.entityType) {
    case 'Movie':
      metadata.push(collectionTileItem.genre);
      metadata.push(collectionTileItem.language);
      metadata.push(collectionTileItem.releaseYear);
      break;

    case 'Program':
      metadata.push(collectionTileItem.genre);
      metadata.push(collectionTileItem.language);
      metadata.push(duration);

      if (collectionTileItem.isLiveStream === true) {
        if (!collectionTileItem.isAvailable) {
          metadata.push(<AvailableDateTimeLabel start={collectionTileItem.availableDate}/>);
        } else {
          playIcon = true;
          videoIdForProgress = collectionTileItem.id;
        }
      }
      break;

    case 'Episode':
      playIcon = true;
      ctaButton = undefined;
      subtitle = collectionTileItem.seriesTitle;
      metadata.push(collectionTileItem.seriesTitle);
      metadata.push(duration);

      if (collectionTileItem.seriesType === 'tv' && collectionTileItem.classificationId) {
        classificationBadge = <ClassificationBadge classification={collectionTileItem.classificationId}/>;
        metadata.push(classificationBadge);
      }
      break;

    case 'Clip':
      metadata.push(collectionTileItem.genre);
      playIcon = true;
      ctaButton = undefined;
      metadata.push(duration);

      if (collectionTileItem.classificationId) {
        classificationBadge = <ClassificationBadge classification={collectionTileItem.classificationId}/>;
        metadata.push(classificationBadge);
      }
      break;

    default:
      break;
  }

  metadata = metadata.filter((el) => {
    return el !== '';
  });

  return (
    <div
      ref={ref}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...other}
    >
      <BaseTile
        classes={classes}
        title={collectionTileItem.title}
        subtitle={subtitle}
        metadata={(
          <Metadata
            list={metadata}
          />
        )}
        description={collectionTileItem.description}
        displayType={displayType}
        link={collectionTileItem.route}
        imageId={collectionTileItem.imageId}
        ctaButton={ctaButton}
        playIcon={playIcon}
        classification={classificationBadge}
        videoIdForProgress={videoIdForProgress}
        onActivate={onActivate}
        onDeactivate={onDeactivate}
        canBeFocused={canBeFocused}
        onClick={onClick}
      />
    </div>
  );
});

export default VideoTile;
