import * as ls from 'local-storage';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Sparkle from '@@src/components/DataDisplay/Icons/Sparkle';
import MenuMessageBanner from '@@src/components/MessageBanner/MenuMessageBanner';
import { useAppSelector } from '@@src/hooks/store';
import { getIsLoggedIn, getIsReady } from '@@stores/UserStore';
import DataLayer from '@@utils/DataLayer';
import { REWIND_PROMO } from '@@utils/constants';

export interface RewindMenuBannerProps {
  classes?: any;
}

const RewindMenuBanner: FunctionComponent<RewindMenuBannerProps> = (props) => {
  const { classes } = props;
  const { t } = useTranslation('common');
  const loggedIn = useAppSelector(getIsLoggedIn);
  const userStateReady = useAppSelector(getIsReady);

  const handleClick = useCallback(() => {
    ls.backend(localStorage);
    ls.set('od.rewindPromo2024.hide', 1);
    DataLayer.events.rewindPromoClick('menu');
  }, []);

  if (
    !userStateReady
    || !loggedIn
    || !REWIND_PROMO.url
  ) {
    return null;
  }

  return (
    <MenuMessageBanner
      icon={<Sparkle/>}
      title={t('rewindBanner.shortTitle')}
      description={t('rewindBanner.shortDescription')}
      data-testid="rewind-banner"
      link={REWIND_PROMO.url}
      classes={classes}
      onClick={handleClick}
    />
  );
};

export default RewindMenuBanner;
