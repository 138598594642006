import { RouteName } from '@@src/routes';
import DataLayer from '@@utils/DataLayer';
import { MYACCOUNT_HOST } from '@@utils/constants';

import { dispatchOpenCreateAccount, dispatchOpenSignIn, dispatchSignOut } from '../components/Login/LoginForm';

export interface MenuItemProp {
  tKey?: string;
  tOptions?: Record<string, unknown>;

  // menu item can have either a route name, url, or action callback
  routeName?: RouteName;

  url?: string;
  urlProps?: Record<string, unknown>;

  action?(): void;
}

export type MenuItemKey = 'home' | 'tvShows' | 'movies' | 'liveTv' | 'news' | 'sport' | 'favourites'
| 'manageAccount' | 'signIn' | 'createAccount' | 'visitSbs' | 'help' | 'contact' | 'signOut';

// list of available menu items
export function getMenuItems(): Record<MenuItemKey, MenuItemProp> {
  return {
    home: {
      tKey: 'menuItem.home',
      routeName: 'home',
    },
    tvShows: {
      tKey: 'menuItem.tvShows',
      routeName: 'tvShows',
    },
    movies: {
      tKey: 'menuItem.movies',
      routeName: 'movies',
    },
    liveTv: {
      tKey: 'menuItem.livetv',
      routeName: 'liveTv',
    },
    news: {
      tKey: 'menuItem.news',
      routeName: 'news',
    },
    sport: {
      tKey: 'menuItem.sport',
      routeName: 'sport',
    },
    favourites: {
      tKey: 'menuItem.favourites',
      routeName: 'favourites',
    },
    manageAccount: {
      tKey: 'menuItem.manageAccount',
      url: MYACCOUNT_HOST,
      urlProps: {
        target: '_blank',
      },
    },
    signIn: {
      tKey: 'menuItem.signIn',
      action() {
        dispatchOpenSignIn();
        DataLayer.events.clickSignInButton();
      },
    },
    createAccount: {
      tKey: 'menuItem.createAccount',
      action() {
        dispatchOpenCreateAccount();
        DataLayer.events.clickCreateAccountButton();
      },
    },
    visitSbs: {
      tKey: 'menuItem.visitSbs',
      url: 'https://www.sbs.com.au',
      urlProps: {
        target: '_blank',
      },
    },
    help: {
      tKey: 'menuItem.help',
      url: 'https://help.sbs.com.au/hc/en-au/categories/360000172615',
      urlProps: {
        target: '_blank',
      },
    },
    contact: {
      tKey: 'menuItem.contact',
      url: 'https://help.sbs.com.au/hc/en-au/requests/new',
      urlProps: {
        target: '_blank',
      },
    },
    signOut: {
      tKey: 'menuItem.signOut',
      action() {
        dispatchSignOut();
      },
    },
  };
}
