import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent, useCallback, useState, useEffect } from 'react';

import ViewMoreButton from '@@src/components/Buttons/ViewMoreButton';
import { OdLink } from '@@src/routes';
import { Person } from '@@src/transformers/CatalogApiCommonTransformer';
import { hash } from '@@utils/helpers';

export interface PersonCollectionProps {
  persons: Person[];
  personRole: string;
  showMoreThreshold?: number,
}

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      display: 'inline',

      '& a ~ a::before': {
        content: '","',
        marginRight: 6,
      },
    },

    personLink: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },

    viewMoreButton: {
      display: 'inline-flex',
      marginLeft: 16,
    },
  });
});

const PersonCollection: FunctionComponent<PersonCollectionProps> = ({
  persons, personRole, showMoreThreshold = 5, ...props
}) => {
  const classes = useStyles(props);
  const id = `${personRole}-${hash(personRole)}`;
  const [expanded, setExpanded] = useState<boolean>(false);
  const canExpand: boolean = persons.length > showMoreThreshold;

  const handleExpandEvent = useCallback(() => {
    document.getElementById(id).querySelectorAll('a.hide').forEach((personElement, index) => {
      personElement.classList.remove('hide');
      if (index === 0) {
        (personElement as HTMLAnchorElement).focus();
      }
    });
    setExpanded(true);
  }, [id]);

  useEffect(() => {
    setExpanded(false);
  }, [persons]);

  return (
    <div id={id} className={classes.root}>
      {
        persons.map((cast, index) => {
          return (
            <OdLink
              key={cast.slug}
              name="person"
              params={{ slug: cast.slug }}
              className={clsx(classes.personLink, { hide: index >= showMoreThreshold })}
              aria-label={personRole ? `${personRole} ${cast.name}` : undefined}
            >
              {cast.name}
            </OdLink>
          );
        })
      }
      {(canExpand && !expanded) && (
        <ViewMoreButton
          classes={{ viewMoreButton: classes.viewMoreButton }}
          onExpand={handleExpandEvent}
          id="view-more-persons"
          aria-controls={id}
          aria-expanded={expanded}
        />
      )}
    </div>
  );
};

export default PersonCollection;
