import * as ls from 'local-storage';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Sparkle from '@@src/components/DataDisplay/Icons/Sparkle';
import MessageBanner from '@@src/components/MessageBanner/MessageBanner';
import { useAppSelector } from '@@src/hooks/store';
import useRewindBannerEnabled from '@@src/hooks/useRewindBannerEnabled';
import { OdLink } from '@@src/routes';
import { getIsLoggedIn, getIsReady } from '@@stores/UserStore';
import DataLayer from '@@utils/DataLayer';
import { REWIND_PROMO } from '@@utils/constants';

export interface RewindBannerProps {
  onClose?: () => void;
  classes?: any;
}

const RewindBanner: FunctionComponent<RewindBannerProps> = (props) => {
  const { onClose, classes } = props;
  const { t } = useTranslation('common');
  const userStateReady = useAppSelector(getIsReady);
  const localStorageKey = 'od.rewindPromo2024.hide';
  const rewindBannerEnabled = useRewindBannerEnabled();
  const loggedIn = useAppSelector(getIsLoggedIn);

  const shouldHide = ls.get(localStorageKey) === 1;

  const closeHandler = useCallback(() => {
    ls.backend(localStorage);

    if (onClose) {
      onClose();
    }

    ls.set(localStorageKey, 1);
  }, [onClose]);

  const handleCtaClick = useCallback(() => {
    closeHandler();
    DataLayer.events.rewindPromoClick('banner');
  }, [closeHandler]);

  if (
    !userStateReady
    || !loggedIn
    || shouldHide === null
    || shouldHide
    || !rewindBannerEnabled
  ) {
    if (onClose && (!rewindBannerEnabled || (shouldHide === true))) {
      onClose();
    }
    return null;
  }

  if (!REWIND_PROMO.url) {
    return null;
  }

  const ctaLinks = (
    <OdLink
      to={REWIND_PROMO.url}
      onClick={handleCtaClick}
    >
      {t('rewindBanner.cta')}
    </OdLink>
  );

  return (
    <MessageBanner
      icon={<Sparkle/>}
      title={t('rewindBanner.title')}
      description={t('rewindBanner.description')}
      data-testid="rewind-banner"
      ctaLinks={ctaLinks}
      onClose={closeHandler}
      classes={classes}
    />
  );
};

export default RewindBanner;
