import * as React from 'react';

import useRewindBannerEnabled from '@@src/hooks/useRewindBannerEnabled';

import rewindNotification from '../../../static/images/rewind-notification.svg';

interface Props {
  children: any
  className: string
}
const MenuIconWithRewindNotification: React.FC<Props> = ({ className, children }) => {
  const rewindBannerEnabled = useRewindBannerEnabled();

  if (!rewindBannerEnabled) {
    return children;
  }

  return (
    <div className={className}>
      {children}
      <img
        src={rewindNotification}
        alt="SBS Rewind"
        style={{
          height: 16, width: 16, position: 'absolute', top: -2, right: -6, opacity: 1,
        }}
      />
    </div>
  );
};

export default MenuIconWithRewindNotification;
