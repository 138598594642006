import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent, ImgHTMLAttributes, useState, createElement } from 'react';
import ReactDOMServer from 'react-dom/server';

import { IMAGE_HOST, IMAGE_RESIZER_URL } from '@@utils/constants';
import { queryString } from '@@utils/helpers';

import { getBreakpointSizeRanges } from '../../styles/breakpoints';

const Noscript = (props) => {
  const { children } = props;
  const staticMarkup = ReactDOMServer.renderToStaticMarkup(children);
  // eslint-disable-next-line react/no-danger
  return <noscript dangerouslySetInnerHTML={{ __html: staticMarkup }}/>;
};

const useStyles = makeStyles<Theme, ImagePropsBase>(() => {
  return createStyles({
    image: {
      width: '100%',
      height: 'auto',
      pointerEvents: 'none',
      '&.lazyload': {
        visibility: 'hidden',
      },
      '&.lazyloaded': {
        visibility: 'inherit',
      },
      display: 'block', // set to block (instead of default inline) to eliminate gap between the image and the next sibling
      objectFit: 'cover',
    },
    placeholder: ({ ratio = '16:9' }) => {
      return {
        aspectRatio: ratio.replace(':', ' / '),
      };
    },
  });
});

interface ImageVariation {
  breakpoint?: string;
  media?: string;
  srcSet: string;
  contentType: string;
}

interface ImageResizerOptions {
  width: number;
  height?: number;
  quality?: number;
  crop?: boolean;
  type?: 'jpeg';
}

function isUrlSupportedByImageResizer(url) {
  return [
    '^http[s]?:\\/\\/([a-z0-9_-]+\\.)+sbs\\.com\\.au',
    '^http[s]?:\\/\\/videocdn-sbs.akamaized.net',
  ].some((pattern) => {
    return RegExp(pattern).test(url);
  });
}

export type ImageConfig = { imageId: string } | { src: string };

export function getImageUrl(imageId) {
  return `${IMAGE_HOST}/${imageId}`;
}

export function getResizedUrl(imageConfig: ImageConfig, options?: ImageResizerOptions): string {
  let url = '';
  const params: any = {
    crop: true,
    ...options,
  };

  if ('imageId' in imageConfig) {
    // if using imageId, use the catalog image host
    url = getImageUrl(imageConfig.imageId);
    delete params.imageId;
  } else if ('src' in imageConfig) {
    // if src is supported, use the image resizer url
    if (isUrlSupportedByImageResizer(imageConfig.src)) {
      url = IMAGE_RESIZER_URL;
      params.url = imageConfig.src;
      delete params.src;
    } else {
      // if the src is not supported, return the src
      return imageConfig.src;
    }
  }

  return `${url}?${queryString(params)}`;
}

export const getHeightFromWidthRatio = (width: number, ratio: string): number => {
  const [w, h] = ratio.split(':');
  const _ratio = parseInt(h, 10) / parseInt(w, 10);

  return Math.round(width * _ratio);
};

/** if size is a number, convert to a string and append `px` */
const transformImageSize = (size: number | string): string => {
  if (typeof size === 'number') {
    return `${size}px`;
  }

  return size;
};

/** process imageSizes rules into HTML5 sizes property */
const generateImageSizes = (imageSizes: Record<string, number | string>): string => {
  const sizes = [];
  const breakpoints = Object.keys(imageSizes);

  breakpoints.forEach((breakpoint) => {
    const imageSize = transformImageSize(imageSizes[breakpoint]);

    if (breakpoint === 'all') {
      sizes.push(imageSize);
    } else {
      sizes.push(`(min-width: ${getBreakpointSizeRanges(breakpoint).min}px) and (max-width: ${getBreakpointSizeRanges(breakpoint).max}px) ${imageSize}`);
    }
  });

  return sizes.join(', ');
};

/** Generates srcSets for the HTML5 picture tag */
const generateSrcSets = (
  imageConfig: ImageConfig,
  ratio: string,
  srcSetWidths = [360, 720, 1440, 1920],
  type: 'jpeg' = 'jpeg',
): ImageVariation => {
  const responsiveSizes = srcSetWidths;
  const srcSets = [];

  responsiveSizes.forEach((responsiveSize) => {
    const imageUrl = getResizedUrl(imageConfig, {
      width: responsiveSize,
      height: getHeightFromWidthRatio(responsiveSize, ratio),
      type,
    });
    srcSets.push(`${imageUrl} ${responsiveSize}w`);
  });

  return {
    srcSet: srcSets.join(', '),
    contentType: type,
  };
};

export type ImageProps = ImagePropsWithSrc | ImagePropsWithId;

interface ImagePropsBase extends ImgHTMLAttributes<HTMLImageElement> {
  /** Array of widths to be generated for the HTML5 srcSet property */
  srcSetWidths?: number[];
  /**
   * Possible sizes of the rendered image per breakpoint
   * example:
   * - { all: 300 } the image is 300px wide at all breakpoints
   * - { xs: 200, all: 300} the image is 200px wide at xs and 300 for the remaining breakpoints
   */
  imageSizes?: Record<string, number | string>;
  /**
   * Image ratio used to calculate the height of the image based on its width.
   * If the ratio provided is different to the source image ratio, the image will be cropped.
   */
  ratio?: string;
  classes?: {
    image: string;
  };
  lazyload?: boolean;
  alt?: string;
}

export interface ImagePropsWithSrc extends ImagePropsBase {
  src: string;
}

export interface ImagePropsWithId extends Omit<ImagePropsBase, 'src'> {
  imageId: string;
}

const Image: FunctionComponent<ImageProps> = (props) => {
  const {
    ratio = '16:9',
    lazyload = true,
    imageSizes = { all: 300 },
    srcSetWidths,
    alt,
    ...rest
  } = props;

  const [imageHasError, setImageHasError] = useState<boolean>(false);
  const defaultWidth = 1280;
  const defaultHeight = getHeightFromWidthRatio(defaultWidth, ratio);
  let imageConfig: ImageConfig;
  const quality = 89;
  const transparentPixelDataUri = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

  let propSrc;
  let propImageId;

  if ('src' in rest) {
    propSrc = rest.src;
    delete rest.src;
  }

  if ('imageId' in rest) {
    propImageId = rest.imageId;
    delete rest.imageId;
  }

  if (propSrc) {
    imageConfig = { src: propSrc };
  }

  if (propImageId) {
    imageConfig = { imageId: propImageId };
  }

  const classes = useStyles(props);

  delete rest.classes;

  const imgProps = {
    className: clsx(classes.image, lazyload && 'lazyload'),
    'data-src': lazyload ? propSrc : undefined,
    src: lazyload ? undefined : propSrc,
    role: 'presentation',
    alt,
    ...rest,
  };

  let imgTag;

  if (imageConfig && !imageHasError) {
    const imgSrc = getResizedUrl(imageConfig, {
      width: defaultWidth,
      height: defaultHeight,
      quality,
    });

    const sizes = generateImageSizes(imageSizes);

    // generate responsive image if using imageId or if the src url is supported
    if ('imageId' in imageConfig || isUrlSupportedByImageResizer(imageConfig.src)) {
      if (!lazyload) {
        // If not lazyload and using responsive images, then use this data:image URL (transparent pixel)
        // to prevent loading of the original SRC followed by the matching responsive image.
        imgProps.src = transparentPixelDataUri;
      } else {
        // If lazyload then let lazysizes handle src
        imgProps['data-src'] = imgSrc;
        imgProps.src = undefined;
      }

      const jpegSrcSets = generateSrcSets(imageConfig, ratio, srcSetWidths, 'jpeg');

      imgTag = createElement(
        'img',
        {
          ...imgProps,
          'data-srcset': lazyload ? jpegSrcSets.srcSet : undefined,
          srcSet: lazyload ? undefined : jpegSrcSets.srcSet,
          sizes,
        },
      );
    } else {
      imgTag = createElement('img', {
        ...imgProps,
        onError: () => {
          setImageHasError(true);
        },
      });
    }
  } else {
    imgTag = (
      <img
        src={transparentPixelDataUri}
        alt={alt}
        className={classes.placeholder}
      />
    );
  }

  return imgTag;
};

export default Image;
