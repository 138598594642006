import { Button as MuiButton, Modal, Fade, Grid, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import PlayIcon from '@@icons/Play';

import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import blue from '../../styles/colors/blue';
import grey from '../../styles/colors/grey';
import orange from '../../styles/colors/orange';
import fontFamily from '../../styles/typography/fontFamily';
import Button from '../Inputs/Button';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      position: 'absolute',
      maxWidth: 732,
      margin: '0 24px',
      width: 'calc(100% - 48px)',
      top: theme.spacing(17),
      left: 0,
      fontSize: '1rem',
      padding: theme.spacing(5, 3),
      [theme.breakpoints.up('sm')]: {
        top: '50%',
        left: '50%',
        margin: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: theme.spacing(7),
        fontSize: '1.125rem',
      },
      textAlign: 'center',
      backgroundColor: orange.darkTangerine,
      color: grey.black,
      borderRadius: 4,

    },
    title: {
      fontWeight: 700,
      fontFamily: fontFamily.secondary,
      fontSize: '1.5rem',
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.75rem',
      },
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      fontSize: '2.5rem',
      padding: 0,
      minWidth: 0,
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1),
      },
      '&:hover': {
        background: 'none',
      },
      '& .close_svg__circle': {
        fill: 'transparent',
      },
      '& .close_svg__x': {
        fill: grey.black,
      },
      '&:focus': {
        outline: `3px solid ${blue.navy}`,
      },
    },
  });
});

interface AdBlockerCardProps {
  open: boolean;
  onClose?(): void;
  // full link to the watch patch, it will do a full page load sos that the resources that were blocked would be reloaded.
  watchLink: string;
}

const AdBlockerModal: FunctionComponent<AdBlockerCardProps> = (props) => {
  const classes = useStyles(props);

  const { open, onClose, watchLink } = props;
  const { t } = useTranslation('common');

  const theme = useTheme();
  const mediaMatchesXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="ad-blocker-modal-title" aria-describedby="ad-blocker-modal-description">
      <Fade in={open}>
        <div className={classes.root}>
          {
            onClose && (
              <MuiButton
                className={classes.closeButton}
                onClick={onClose}
                aria-label={t('adBlockerCard.closeButton')}
              >
                <CloseIcon/>
              </MuiButton>
            )
          }
          <div id="ad-blocker-modal-title" className={classes.title}>{t('adBlockerCard.title')}</div>
          <p id="ad-blocker-modal-description">{t('adBlockerCard.description')}</p>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} sm="auto">
              <Button
                fullWidth={mediaMatchesXs}
                alternateColor
                buttonType="secondary"
                href="https://help.sbs.com.au/hc/en-au/articles/360002033956"
                target="_blank"
              >
                {t('adBlockerCard.learnMore')}
              </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button
                fullWidth={mediaMatchesXs}
                alternateColor
                startIcon={<PlayIcon/>}
                // Using href instead of to as to force a page reload when going to the watch page.
                // This allows user to disable the adblocker and click on this button without having to refresh
                // the current page
                href={watchLink}
              >
                {t('adBlockerCard.playVideo')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default AdBlockerModal;
