import { find, uniq } from 'lodash';
import { DateTime } from 'luxon';

import OnDemand2 from '@@src/@types/OnDemand2';
import { Suggestion } from '@@src/services/SearchService';
import { transformPageSectionCollectionToRowV2 } from '@@src/transformers/CatalogApiCollectionTransformer';
import { components } from '@@types/CatalogueApi';
import OnDemand from '@@types/OnDemand';
import { IMAGE_HOST } from '@@utils/constants';
import Logger from '@@utils/logger/Logger';

import { OdLinkProps } from '../routes';

export function transformPopularSearches(terms): Suggestion[] {
  return terms.map((term) => {
    return {
      id: term,
      label: term,
    };
  });
}

function checkSupportedConsumerAdvices(value): asserts value is OnDemand.ConsumerAdvice {
  const supportedValues: OnDemand.ConsumerAdvice[] = ['s', 'l', 'a', 'v', 'n', 'd', 'h', 'w'];

  if (!supportedValues.includes(value)) {
    throw new Error('Consumer advice is not supported');
  }
}

export function transformConsumerAdvices(consumerAdvices: string[]): OnDemand.ConsumerAdvice[] {
  return consumerAdvices.map<OnDemand.ConsumerAdvice>((ca) => {
    const _ca = ca.toLowerCase();
    try {
      checkSupportedConsumerAdvices(_ca);
      return _ca;
    } catch (e) {
      // We are not using/displaying the "W" consumer advice, there is no real need to log a warning.
      if (_ca !== 'w') {
        Logger.warn('Consumer advice is not supported', {
          consumerAdvice: _ca,
        });
      }

      return null;
    }
  }).filter((ca) => {
    return !!ca;
  });
}

function checkSupportedClassification(value): asserts value is OnDemand.Classification {
  const supportedValues: OnDemand.Classification[] = ['P', 'C', 'PG', 'G', 'M', 'MA15+'];

  if (!supportedValues.includes(value)) {
    throw new Error('Classificationis not supported');
  }
}

export function transformClassification(classification: string): OnDemand.Classification {
  try {
    checkSupportedClassification(classification);
    return classification;
  } catch (e) {
    return null;
  }
}

export function transformCrewToDirectors(crews: components['schemas']['crew'][]): Person[] {
  return uniq(crews.filter((crew) => {
    return crew.role === 'Director' || crew.role === 'Co-Director';
  }).map((crew) => {
    return {
      id: crew.id,
      slug: crew.slug,
      name: crew.name,
    };
  }));
}

export interface Person {
  id: string;
  slug: string;
  name: string;
}

export function transformCasts(casts: components['schemas']['cast'][]): Person[] {
  const whitelist = ['Actor', 'Anchor', 'Correspondent', 'Guest Star', 'Guest Voice', 'Guest',
    'Host', 'Judge', 'Music Performer', 'Musical Guest', 'Narrator', 'Voice', 'Self'];

  return casts.filter((crew) => {
    return whitelist.includes(crew.role);
  }).map((crew) => {
    return {
      id: crew.id,
      slug: crew.slug,
      name: crew.name,
    };
  });
}

export interface Availability {
  availableDate: string;
  expiredDate: string;
  available: boolean;
  expired: boolean;
}

export function transformAvailability(availability: { start: string; end: string; }): Availability {
  const startDate = DateTime.fromISO(availability.start, { setZone: true });
  const availableDate = availability.start ? startDate.toISO() : null;
  const endDate = DateTime.fromISO(availability.end, { setZone: true });
  const expiredDate = availability.end ? endDate.toISO() : null;
  const expired = availability.end ? endDate.diffNow().as('seconds') < 0 : false;
  const available = !expired && (availability.start ? startDate.diffNow().as('seconds') < 0 : true);

  return {
    availableDate,
    expiredDate,
    available,
    expired,
  };
}

export function transformAnnouncement(announcement: {
  title: string;
  availability: { start: string; end: string; };
  type: string
}): OnDemand.Announcement | null {
  const availability = transformAvailability(announcement.availability);
  if (availability.available) {
    return {
      title: announcement.title,
      type: announcement.type,
    };
  }

  return null;
}

export function transformTextTracksToSubtitles(textTracks: components['schemas']['textTrack'][]): string[] {
  return uniq(textTracks
    .filter((textTrack) => {
      return textTrack.type === 'SUBTITLE' || textTrack.type === 'CAPTION';
    })
    .map((textTrack) => {
      return textTrack.type === 'CAPTION' ? `${textTrack.language} (CC)` : textTrack.language;
    }));
}

export type EntityType = 'MOVIE' | 'CLIP'
| 'TV_SERIES' | 'SPORTS_SERIES' | 'NEWS_SERIES'
| 'TV_EPISODE' | 'SPORTS_EPISODE' | 'NEWS_EPISODE'
| 'TV_PROGRAM' | 'SPORTS_PROGRAM' | 'NEWS_PROGRAM'
| 'PAGE' | 'CURATED_COLLECTION' | 'DYNAMIC_COLLECTION';

type OdType = 'Movie' | 'Episode' | 'OneOff' | 'Clip' | 'TVSeries';

export function transformEntityType(entityType: EntityType): OdType {
  let type = null;
  if (entityType === 'MOVIE') {
    type = 'Movie';
  } else if (entityType === 'CLIP') {
    type = 'Clip';
  } else if (['TV_SERIES', 'SPORTS_SERIES', 'NEWS_SERIES'].includes(entityType)) {
    type = 'TVSeries';
  } else if (['TV_EPISODE', 'SPORTS_EPISODE', 'NEWS_EPISODE'].includes(entityType)) {
    type = 'Episode';
  } else if (['TV_PROGRAM', 'SPORTS_PROGRAM', 'NEWS_PROGRAM'].includes(entityType)) {
    type = 'OneOff';
  } else if (entityType === 'PAGE') {
    type = 'Page';
  } else if (['CURATED_COLLECTION', 'DYNAMIC_COLLECTION'].includes(entityType)) {
    type = 'Collection';
  }
  return type;
}

/**
 * Filter out 'Undetermined' language
 * @param languages
 */
export function transformLanguages(languages: string[]): string[] {
  return languages.filter((language) => {
    return language !== 'Undetermined';
  });
}

export function transformEntityTypeToSeriesType(entityType: string) {
  let seriesType;
  if (entityType === 'TV_SERIES' || entityType === 'TV_EPISODE') {
    seriesType = 'tv';
  } else if (entityType === 'NEWS_SERIES' || entityType === 'NEWS_EPISODE') {
    seriesType = 'news';
  } else if (entityType === 'SPORTS_SERIES' || entityType === 'SPORTS_EPISODE') {
    seriesType = 'sports';
  }
  return seriesType;
}

export function transformEntityTypeToProgramType(entityType: string): OnDemand2.CollectionItemProgramType {
  let programType;
  if (entityType === 'TV_PROGRAM') {
    programType = 'tv';
  } else if (entityType === 'NEWS_PROGRAM') {
    programType = 'news';
  } else if (entityType === 'SPORTS_PROGRAM') {
    programType = 'sports';
  }
  return programType;
}

interface SeriesRoutableItem {
  entityType: 'TV_SERIES' | 'SPORTS_SERIES' | 'NEWS_SERIES';
  slug: string;
}

interface EpisodeRoutableItem {
  entityType: 'TV_EPISODE' | 'SPORTS_EPISODE' | 'NEWS_EPISODE';
  slug: string;
  mpxMediaID: number;
  seriesSlug: string;
  seasonSlug?: string;
}

interface VideoRoutableItem {
  entityType: 'MOVIE' | 'CLIP' | 'TV_PROGRAM' | 'SPORTS_PROGRAM' | 'NEWS_PROGRAM';
  slug: string;
  mpxMediaID: number;
}

interface CollectionRoutableItem {
  entityType: 'CURATED_COLLECTION' | 'DYNAMIC_COLLECTION' | 'HERO_COLLECTION';
  slug: string;
}

interface PageRoutableItem {
  entityType: 'PAGE';
  slug: string;
}

interface PersonRouteableItem {
  entityType: 'PERSON';
  slug: string;
}

type RouteableItem = SeriesRoutableItem | EpisodeRoutableItem | VideoRoutableItem | CollectionRoutableItem | PageRoutableItem | PersonRouteableItem;

export function getRoute<T extends RouteableItem>(item: T): OdLinkProps {
  switch (item.entityType) {
    case 'TV_SERIES':
    case 'NEWS_SERIES':
    case 'SPORTS_SERIES':
      return {
        name: 'series',
        params: {
          seriesType: transformEntityTypeToSeriesType(item.entityType),
          slug: item.slug,
        },
      };
    case 'TV_EPISODE':
    case 'SPORTS_EPISODE':
    case 'NEWS_EPISODE':
      return {
        name: 'seriesEpisode',
        params: {
          id: item.mpxMediaID.toString(),
          seriesSlug: item.seriesSlug,
          ...(item.seasonSlug && { seasonSlug: item.seasonSlug }),
          episodeSlug: item.slug,
          seriesType: transformEntityTypeToSeriesType(item.entityType),
        },
      };
    case 'MOVIE':
      return {
        name: 'movie',
        params: {
          id: item.mpxMediaID.toString(),
          slug: item.slug,
        },
      };
    case 'TV_PROGRAM':
    case 'SPORTS_PROGRAM':
    case 'NEWS_PROGRAM':
      return {
        name: 'program',
        params: {
          id: item.mpxMediaID.toString(),
          slug: item.slug,
          programType: item.entityType.toLocaleLowerCase().split('_').shift(),
        },
      };
    case 'CLIP':
      return {
        name: 'video',
        params: {
          id: item.mpxMediaID.toString(),
          slug: item.slug,
        },
      };
    case 'CURATED_COLLECTION':
    case 'DYNAMIC_COLLECTION':
    case 'HERO_COLLECTION':
      return {
        name: 'collection',
        params: {
          slug: item.slug,
        },
      };
    case 'PAGE':
      return {
        name: 'page',
        params: {
          slug: item.slug,
        },
      };
    case 'PERSON':
      return {
        name: 'person',
        params: {
          slug: item.slug,
        },
      };
    default:
      // @ts-ignore: log error in case new entityType was introduced by the API
      Logger.error(`Unable to get route for ${item.entityType}.`);
      return null;
  }
}

export interface CatalogApiImage {
  category: string;
  id: string;
}

interface ImageProperties {
  ratio?: string;
  type?: string;
  width?: number;
  height?: number;
}

export function getImageIdByProperties(images: CatalogApiImage[], properties: ImageProperties): string | null {
  const image = find(images, (_image) => {
    const [ratio, width, height, type] = _image.category.split('|');

    let match = true;

    if ('ratio' in properties && properties.ratio !== ratio) {
      match = false;
    }

    if ('type' in properties && properties.type !== type) {
      match = false;
    }

    if ('width' in properties && properties.width !== parseInt(width, 10)) {
      match = false;
    }

    if ('height' in properties && properties.height !== parseInt(height, 10)) {
      match = false;
    }

    return match;
  });

  return image ? image.id : null;
}

export function getImageUrlByType(images: CatalogApiImage[], type: string): string {
  const imageId = getImageIdByProperties(images, { type });

  return imageId ? `${IMAGE_HOST}/${imageId}` : null;
}

export function transformSponsorship(sponsorships: components['schemas']['sponsorship'][]): OnDemand.Sponsorship | null {
  if (!sponsorships || sponsorships.length === 0) {
    return null;
  }

  // get the first sponsorship which is not expired
  const found = sponsorships.find((sponsorship) => {
    const startDate = DateTime.fromISO(sponsorship.availability.start, { setZone: true });
    const endDate = DateTime.fromISO(sponsorship.availability.end, { setZone: true });
    const expired = endDate.diffNow().as('seconds') < 0;
    const available = !expired && startDate.diffNow().as('seconds') < 0;
    return available;
  });

  if (!found) {
    return null;
  }

  return {
    id: found.id,
    title: found.title,
    text: found.text,
    images: found.images.filter((image): image is { id: string; category: string } => {
      return !!image.id && !!image.category;
    }),
  };
}

export function transformPerson(person): OnDemand2.Person {
  const rowV2 = transformPageSectionCollectionToRowV2(person, 'shelf', '16:9');

  return {
    id: person.id,
    name: person.name,
    biography: person.biography,
    imageId: getImageIdByProperties(person.images, { ratio: '3:4', type: 'PHOTO_HEADSHOT' }),
    backgroundImage16x9: getImageIdByProperties(person.images, { ratio: '16:9', type: 'KEY_ART' }),
    backgroundImage2x3: getImageIdByProperties(person.images, { ratio: '2:3', type: 'KEY_ART' }),
    featuredItems: rowV2.collection,
  };
}
